import React, { useEffect, useState } from "react";

export const Navbar = ({
  setSelectTeb,
  selectTebs,
  scrollToHome,
  scrollToAbout,
  scrollToTokenomics,
  scrollToFaq,
  scrollToRoadmap,
  scrollTobuynow,
  scrollToPeople,
  scrollToBusiness,
  scrollToHowToBuyRef,
  scrollToReferralEarn,
}) => {
  const transactions = [
    { wallet: "0x23762d3....11946", crudo: 124459, usd: 995.67 },
    { wallet: "0xb1b835....6951dd", crudo: 67392, usd: 539.136 },
    { wallet: "0xa1e5e6....7f4492", crudo: 1586, usd: 12.688 },
    { wallet: "0x7217b8....612d2", crudo: 368711, usd: 2949 },
    { wallet: "0xb2dede....c519c", crudo: 674019, usd: 5392.15 },
    { wallet: "0xb8c3b4....a5212c", crudo: 400, usd: 3.2 },
    { wallet: "0x1618a2....228d", crudo: 4568, usd: 36.5 },
    { wallet: "0x576dc13....06e1e", crudo: 47313, usd: 378.5 },
    { wallet: "0xb4576e7....9c852c", crudo: 187500, usd: 1500 },
    { wallet: "0x1618a28....ee228d", crudo: 76890, usd: 615 },
    { wallet: "0x892eb2f8....b10b1b", crudo: 2500000, usd: 20000 },
    { wallet: "0x2c13d27f1....3141", crudo: 5143000, usd: 41144 },
    { wallet: "0xb45....99c852c", crudo: 4965000, usd: 39721 },
    { wallet: "0x675....27d2b", crudo: 625000, usd: 5000 },
    { wallet: "0x22e7c3....01472", crudo: 208750, usd: 1670 },
    { wallet: "0x2c13d2....9f3141", crudo: 9875, usd: 79 },
    { wallet: "0x4a24ef....27100f", crudo: 601250, usd: 4810 },
    { wallet: "0x3cc0ff2....2160fb4", crudo: 980375, usd: 7843 },
    { wallet: "0x2c13d....f3141", crudo: 64000, usd: 512 },
    { wallet: "0xb4576....c852c", crudo: 8125, usd: 65 },
    { wallet: "0x4aa3b2e....e9627c", crudo: 100000, usd: 800 },
    { wallet: "0x4b16fc6....57da5", crudo: 68750, usd: 550 },
    { wallet: "0x50390d2....03c36e9", crudo: 27500, usd: 220 },
    { wallet: "0x19d9....905dd3", crudo: 16000, usd: 128 },
    { wallet: "0xc86659e....9fb805a", crudo: 87500, usd: 700 },
    { wallet: "0x29cfe62f....3ff8b0", crudo: 57375, usd: 459 },
    { wallet: "0xbdb....af92334", crudo: 35375, usd: 283 },
    { wallet: "0x20c200....91aa4d", crudo: 470500, usd: 3764 },
    { wallet: "0x310b4bf....db4507", crudo: 1120625, usd: 8965 },
    { wallet: "0x7fec381....f01253", crudo: 1151250, usd: 9210 },
    { wallet: "0x24737a....b9fe3", crudo: 2681250, usd: 21450 },
    { wallet: "0x8296....dea44d", crudo: 122500, usd: 980 },
    { wallet: "0x327a75d....bbc3aa", crudo: 153750, usd: 1230 },
    { wallet: "0x7e927....d4cf31c", crudo: 34225, usd: 273.8 },
    { wallet: "0x500ef....08c17c3", crudo: 109937.5, usd: 879.5 },
    { wallet: "0xdb7943....ab311", crudo: 88500, usd: 708 },
    { wallet: "0x72962....4ae3d", crudo: 12375, usd: 99 },
    { wallet: "0x642c741....40618", crudo: 46250, usd: 370 },
    { wallet: "0x642c741....440618", crudo: 12500, usd: 100 },
    { wallet: "0x59e78....c2ead8b", crudo: 193750, usd: 1550 },
    { wallet: "0xd62d....00c941", crudo: 625000, usd: 5000 },
    { wallet: "0xc34469....8ec9c", crudo: 22500, usd: 180 },
    { wallet: "0x8a55....aae7b", crudo: 62250, usd: 498 },
    { wallet: "0x43ad8796....37a6", crudo: 62500, usd: 500 },
    { wallet: "0xac62cf....d4e1a4", crudo: 187500, usd: 1500 },
    { wallet: "0x33be371....6cefb", crudo: 15000, usd: 120 },
    { wallet: "0x59e7821....2ead8b", crudo: 601125, usd: 4809 },
    { wallet: "0x154605....a874ca", crudo: 73750, usd: 590 },
    { wallet: "0x6a3399....e6cbc", crudo: 463087.5, usd: 3704.7 },
    { wallet: "0x0f7d5af9....1c37dbe", crudo: 110000, usd: 880 },
    { wallet: "0x35ef1362....db741d4", crudo: 75000, usd: 600 },
    { wallet: "0xb9906a3....347a18fe84c", crudo: 237500, usd: 1900 },
    { wallet: "0xefd1....404839eaf", crudo: 97987.5, usd: 783.9 },
    { wallet: "0xa3060c....d9b3d23", crudo: 337500, usd: 2700 },
    { wallet: "0x5b922....b689b9", crudo: 60000, usd: 480 },
    { wallet: "0x90fc....266c1a", crudo: 25000, usd: 200 },
    { wallet: "0x8a55d7.....aaae7b", crudo: 11250, usd: 90 },
    { wallet: "0x42acc3....cd02f", crudo: 57500, usd: 460 },
    { wallet: "0x5a1d8....364744", crudo: 70000, usd: 560 },
    { wallet: "0x92e0b....a3fa2e", crudo: 41250, usd: 330 },
    { wallet: "0xe14a3b....4aea5ee2", crudo: 12500, usd: 100 },
    { wallet: "0x93345....cf5287", crudo: 48025, usd: 384.2 },
    { wallet: "0xa7568d....a520", crudo: 58987.5, usd: 471.9 },
    { wallet: "0x981ee....3c0f129", crudo: 75100, usd: 600.8 },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [newClass, setNewClass] = useState("f_popop");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % transactions.length);
    }, 12000);

    return () => clearInterval(intervalId);
  }, []);

  const currentTransaction = transactions[currentIndex];

  const addClass = () => {
    setNewClass("popop_hide");
  };
  const handleClick = () => {
    window.open("/img/SmartContract_Audit_Solidproof_CrudoProtocol.pdf","_blank")
  };
  return (
    <>
      <div className={newClass}>
        <div className="d-inline-flex     align-items-center">
          <h6 className="mb-0">
            <span className="chakra-text css-1081t4c">
              [{currentTransaction.wallet.slice(0, 6)}••••
              {currentTransaction.wallet.slice(-4)}]
            </span>{" "}
            bought {currentTransaction.crudo} CRUDO worth $
            {currentTransaction.usd}
          </h6>
         
          <a
            className="btn btn_white ml-3"
            href="/#buy-now"
            onClick={scrollTobuynow}
          >
            Buy now
          </a>
        </div>

        <button
          type="button"
          className="btn btn_cancil"
          aria-label="Close"
          onClick={() => addClass()}
        >
          <i className="bi bi-x-lg"></i>
        </button>
      </div>

      <div className="top_fix">
        <div className="container-fluid">
         <div>Initial Price: <span>$0.008</span></div>
         <div>Price Now: <span>$0.01 <span className="text-success">(+25%)</span></span></div>
         <div>Next Stage Price: <span>$0.015 <span className="text-success">(+50%)</span></span></div>
         <div>Listing Price: <span>$0.20 <span className="text-success">(+2000%)</span></span></div>
         </div>
      </div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo.svg"
              alt="Crudo Protocol Logo"
              className="logo"
            />
          </a>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ml-auto line">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#about-us"
                  onClick={scrollToAbout}
                >
                  About
                </a>
              </li>
              <li className="nav-item dropdown">
            
                <a   href="#" className="dropdown-toggle nav-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Ecosystem
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item"  href="/#business"  onClick={scrollToBusiness}>Crudo For Business</a>
                  <a className="dropdown-item"   href="/#people"
                                onClick={scrollToPeople}>   Crudo for People</a>

                </div> 
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#business"
                  onClick={scrollToBusiness}
                >
                  Crudo For Business
                </a>
              </li> */}

              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#people"
                  onClick={scrollToPeople}
                >
                  Crudo for People
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#crudo-app"
                  onClick={scrollToPeople}
                >
                  Crudo App
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#tokenomics"
                  onClick={scrollToTokenomics}
                >
                  {" "}
                  Tokenomics{" "}
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/#roadmap"
                  onClick={scrollToRoadmap}
                >
                  Roadmap
                </a>
              </li>
              <li className="nav-item mb-3 mb-lg-0 ml-lg-2 text-center ">
                <a
                  className="btn btn_border"
                  target="_blank"
                  href="https://crudo-protocol.gitbook.io/crudo-whitepaper-v2"
                >
                  Whitepaper
                </a>
              </li>
              <li className="nav-item mb-3 mb-lg-0 text-center ">
                <a
                  className="btn ml-md-3"
                  href="https://github.com/solidproof/projects/tree/main/2024/Crudo%20Protocol"
                  target="_blank"
                  // onClick={handleClick}
                >
                  Audit
                </a>
              </li>

              <li className="nav-item mb-3 mb-lg-0 text-center ">
                <a
                  className="btn ml-md-3"
                  href="https://www.tropee.com/crudoprotocol"
                  target="_blank"

                  // onClick={scrollToHowToBuyRef}
                >
                  WIN $100k
                </a>
              </li>
            </ul>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="bi bi-justify" />
            </span>
          </button>
        </div>
        <a
          className="btn right_btn"
          href="/how-to-buy"
          onClick={scrollToHowToBuyRef}
        >
          How to Buy
        </a>
        <a
          className="btn right_btn2"
          href="/#referral-earn"
          onClick={scrollToReferralEarn}
        >
          Refer To Earn
        </a>
      </nav>
    </>
  );
};
