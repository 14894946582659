import React from "react";


export const Footer = () => {
  return (
    <>
      <section className="contact  footer" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-4  mb-md-0">
              <img src="/img/logo.svg" alt="footer-Logo" className="logo" />
            </div>
            <div className="col-md-7 mb-4 mb-md-0">
              <h3 className>
                Start your journey with <br />
                CRUDO right away!
              </h3>
            </div>
          </div>
          <ul className="social">
            <li className>
              <a className href="https://twitter.com/CrudoProtocol" target="_blank">
                <i className="bi bi-twitter-x" />
              </a>
            </li>
            <li className>
              <a
                className
                href="https://www.instagram.com/crudoprotocol/"
                target="_blank"
              >
                {" "}
                <i className="bi bi-instagram" />
              </a>
            </li>
            <li className>
              <a className href="https://t.me/crudoprotocol" target="_blank">
                <i className="bi bi-telegram" />
              </a>
            </li>
            <li className>
              <a className href="https://medium.com/@crudoprotocol/introducing-crudo-protocol-revolutionizing-the-oil-and-gas-industry-with-blockchain-afc66dfe7ac6" target="_blank">
                <i className="bi bi-medium" />
              </a>
            </li>
          </ul>
          {/* <div className="  mt-md-5 mt-4   ">
            <ul className="lr_manu  d-sm-flex justify-content-between">
              <li>
                <a href="#">News</a>
              </li>
              <li>
                <a
                  href="https://crudo-protocol.gitbook.io/crudo-whitepaper-v1"
                  target="_blank"
                >
                  Whitepaper
                </a>
              </li>
          
              <li>
                <a href="#">Legal</a>
              </li>
              <li>
                <a href="#">how to buy</a>
              </li>
            </ul>
          </div> */}
          <div className="copyright">
            <div className="row align-items-center">
              <div className="col-sm-7   terms_link  mb-4 mb-sm-0">
              <a
                  className="btn "
                  href="https://www.tropee.com/crudoprotocol"
                  target="_blank"
                  
                  // onClick={scrollToHowToBuyRef}
                >
                  WIN $100k
                </a>
                <a
                  className="btn  "
                  href="/how-to-buy"
                  // onClick={scrollTobuynow}
                >
                How to Buy
                </a>
                <a href="/terms-of-use">Terms and conditions</a>
                <a href="/privacy-policy">Privacy Policy</a>
                
              </div>
              <div className="col-sm-5  text-sm-right">
                <p className="op20 mb-0">
                  © 2024 Crudo Protocol. All rights reserved.
                </p>
              </div>
            </div>
          </div>
          <p className="op20 mt-2">
            Disclaimer:
            <br />
            Digital currencies may be unregulated in your jurisdiction. The value of digital currencies may go down as well as up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction.

          </p>
        </div>
      </section>
   

    </>
  );
};
