import React from "react";
import { Navbar } from "../widgets/Navbar";

import { Footer } from "../widgets/Footer";

export const TermsOfUse = () => {
  return (
    <section class="bg1 ">
      <Navbar />

      <div class="container">
        <section class=" p70 pn_page_pt ">
          <h1 class="text-center hadding mb-md-5 mb-3">
            <span class="t_gr">Terms of Use</span>
          </h1>

          <h6>1.1 Acceptance of Terms</h6>
          <p>
            {" "}
            These Terms & Conditions establish a legally binding agreement
            between you (whether personally or on behalf of an entity) and Crudo
            Protocol LLC (referred to as the "Company," "Crudo Protocol," "we,"
            "us," or "our"). These terms govern your access to and use of the
            crudoprotocol.com website, along with any other associated media
            forms, social media channels, or mobile websites (collectively
            referred to as the "platform"). By accessing the platform, you
            acknowledge that you have read, comprehended, and agreed to abide by
            all these Terms & Conditions. If you do not agree to these Terms of
            Use in their entirety, you are expressly prohibited from using the
            website.
          </p>
          <h6> 1.2 Legal Authorization</h6>
          <p>
            By accessing or using crudoprotocol.com, you affirm that you are of
            legal age and have the legal capacity to enter into this agreement.
            If you are accessing or using crudoprotocol.com on behalf of an
            organization, you represent and warrant that you have the authority
            to bind that organization to these Terms.
          </p>
          <h6>1.3 Site Interface Disclaimer </h6>
          <p>
            crudoprotocol.com’s website provides access to a decentralized
            protocol on the Binance Smart Chain Network, allowing “you” the
            user, to exchange crypto currency assets in the form of ETH, USDT
            and BNB for Crudo Protocol’s utility token $CRUDO. Using the
            platform may require that “you” pay a fee on the Crudo Protocol
            platform to perform a transaction. You acknowledge and agree that
            Crudo Token has no control over any transactions, the method of
            payment of any transaction, or any actual payments of transactions.
            You must ensure that you have sufficient funds to complete any
            transaction on the platform before initiating such a transaction.
          </p>
          <h6>2.1 Compliance with Laws </h6>
          <p>
            When using crudoprotocol.com, you agree to comply with all
            applicable laws, regulations, and third-party rights.
          </p>
          <h6> 2.2 Prohibited Activities</h6>
          <p>
            You agree not to engage in any illegal, fraudulent, or harmful
            activities using crudoprotocol.com, including but not limited to: a.
            Violating any applicable laws, regulations, or third-party rights.
            b. Collection or compiling data from the platform without
            permission. c. Deceive or trick us or other users to obtain
            sensitive account information. d. Bypassing or tampering with
            security features of the platform. e. Misusing support services or
            submitting false reports. f. Using the platform for advertising or
            selling goods and services. g. Fame or linking to the platform
            without authorization. h. Uploading viruses or spam that disrupts
            platform usage. i. Removing copyright notices from content. j.
            Selling or transferring your profile. k. engaging in cyberattacks or
            compromise system integrity. l. Copying or modifying the platform
            software without permission. m. Reverse engineer or disassemble
            platform software. n. Engaging in market manipulation or violating
            trading market regulations.
          </p>
          <h6>3.1 Availability </h6>
          <p>
            While we make every effort to ensure uninterrupted access to
            crudoprotocol.com, we cannot guarantee the continuous or error-free
            operation of our services. We reserve the right to temporarily or
            permanently suspend or terminate crudoprotocol.com for maintenance,
            upgrades, security, or other reasons, without prior notice.
          </p>

          <h6>3.2 Services Disclaimer </h6>
          <p>
            crudoprotocol.com provides information and content on its platforms.
            However, crudoprotocol.com is not intended for distribution or use
            in jurisdictions or countries where such distribution or use would
            violate local laws or regulations or necessitate compliance with
            registration requirements. If you choose to access crudoprotocol.com
            from such locations, you do so voluntarily and assume sole
            responsibility for complying with applicable local laws.
          </p>

          <h6> 4.1 Ownership</h6>
          <p>
            Intellectual property found in the materials contained in the
            Website, as well as any other media form, social media channel or
            mobile website related, linked, or otherwise connected thereto
            (collectively, the “platform”) are owned by or licensed to
            crudoprotocol.com, and are protected by applicable copyright and
            trademark laws. We grant users permission to download one copy of
            the materials for personal, non-commercial transitory use only.
          </p>

          <h6>4.2 License </h6>
          <p>
            Subject to your compliance with these Terms, constitutes the grant
            of a license, not a transfer of title.You agree not to use
            crudoprotocol.com for any commercial purposes without our prior
            written consent. This license shall automatically terminate if you
            violate any of these restrictions or the Terms of Service and may be
            terminated by crudoprotocol.com at any time
          </p>

          <h6>5.1 Volatility </h6>
          <p>
            $CRUDO is subject to significant price volatility and market
            fluctuations. You understand and acknowledge the risks associated
            with investing or holding a $CRUDO, and you agree that
            crudoprotocol.com shall not be responsible for any financial losses
            incurred.
          </p>

          <h6>5.2 Third-party Services </h6>
          <p>
            The Service may provide links to third-party websites or services
            for your convenience. Company does not endorse or control these
            third-party services, and you agree that Company shall not be
            responsible for any content, products, or services provided by such
            third parties. For more information about third-party services,
            please review our Cookies and Privacy Policies.
          </p>

          <h6> 5.3 User Responsibility</h6>
          <p>
            You are solely responsible for the security and custody of your
            $CRUDO holdings, including but not limited to your private keys,
            passwords, and access codes. You agree to take appropriate measures
            to safeguard your $CRUDO holdings and protect them from unauthorized
            access or loss.
          </p>

          <h6> 6.1 Modifications</h6>
          <p>
            crudoprotocol.com reserves the right to modify or terminate the
            Service or these Terms at any time, with or without prior notice. It
            is your responsibility to review the Terms periodically for any
            changes. Your continued use of the Service after the modifications
            will constitute your acceptance of the revised Terms.
          </p>

          <h6> 6.2 Termination</h6>
          <p>
            crudoprotocol.com may suspend or terminate your access to the
            Service at any time, with or without cause, in its sole discretion.
            Upon termination, all licenses and rights granted to you under these
            Terms will immediately cease.
          </p>

          <h6>7. Severability </h6>
          <p>
            Any term found in these Terms of Service which is wholly or
            partially void or unenforceable is severed to the extent that it is
            void or unenforceable. The validity of the remainder of these Terms
            of Service is not affected.
          </p>

          <h6>8. Entire Agreement </h6>
          <p>
            These Terms constitute the entire agreement between you and
            crudoprotocol.com regarding your use of crudoprotocol.com and
            supersede any prior agreements or understandings, whether oral or
            written.
          </p>

          <h6> 9. Contact Us</h6>
          <p>
            Questions, concerns, and inquiries regarding these Terms of Service
            can be directed to crudoprotocol.com, through this contact email
            info@crudoprotocol.com By using crudoprotocol.com, you agree to
            abide by these Terms of Service.
          </p>
        </section>
      </div>
      <Footer />
    </section>
  );
};
