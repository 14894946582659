import * as opsService from "../Ops";

import {
  checkUserApi,
  signupUserApi,getAuthApi
} from "../../Constant/Api";

const checkUser = async (data, token) => {
  let result = await opsService.postdata(checkUserApi, data, token);

  return result;
};
const signupUser = async (data, token) => {
  let result = await opsService.postdata(signupUserApi, data, token);

  return result;
};
const authUser = async (data, token) => {
  let result = await opsService.postdata(getAuthApi, data);

  return result;
};


export { checkUser, signupUser,authUser };
