import React from "react";
import { Navbar } from "../widgets/Navbar";
import { Footer } from "../widgets/Footer";
import videoFile from "../../components/How To Buy/crudoprotocol_Final.mp4";
export const HowToBuy = () => {
  const handleClick = () => {
    window.location.href = "https://youtu.be/r5QNzJwlJKg";
  };
  return (
    <>
      <Navbar />
      <section className="bg1">
        <section class=" p70 pn_page_pt ">
          <div className="container">
            <h2 className="text-center hadding mb-4 ">
              Our
              <span className="tgr fwb"> Videos</span>
            </h2>
            <div className="row mb-4 mb-md-5">
              <div className="col-md-8 m-auto" data-aos="fade-up">
                <div className="video_box box p-0 text-center">
                  <a
                    className="video-container"
                    target="_blank"
                    href="https://www.youtube.com/watch?v=r5QNzJwlJKg"
                  >
                    <img
                      className="img-fluid"
                      src="/img/video_img.png"
                      alt="video"
                    />
                  </a>
                </div>
              </div>
            </div>

            <h1 className="text-center hadding mb-md-5 mb-3">
              Buying CRUDO with <span className="t_gr"> Cryptocurrency</span>
            </h1>

            <h6>Prepare Your Wallet</h6>
            <p>
              {" "}
              Make sure you have one of the supported wallets. For desktop, use
              the Metamask browser extension. For mobile, use TrustWallet or
              MetaMask with their built-in browsers.
            </p>

            <h6>Connect Your Wallet</h6>
            <p>
              {" "}
              Click the "Connect Wallet" button on the top left of the Buy
              widget and connect your DeFi wallet.
            </p>

            <h6>Select Chain and Currency</h6>
            <p>
              {" "}
              Choose the blockchain you want to use (Binance Smart Chain,
              Ethereum, Polygon, or Avalanche C-Chain) and select your currency
              (BNB, ETH, MATIC, AVAX, USDT, or USDC).
            </p>

            <h6>Specify Purchase Amount</h6>
            <p>
              {" "}
              Decide how many CRUDO tokens you want to buy or how much of your
              assets you want to spend, then click "Approve & Buy". If you need
              more assets, transfer them from a crypto exchange to your wallet
              or buy more via Metamask or TrustWallet marketplace.
            </p>

            <h6>Confirm Transaction: </h6>
            <p>
              Approve the spending cap in your wallet. After approving, a new
              transaction will appear to confirm your transfer and purchase of
              CRUDO tokens. Do not refresh the page for 30 seconds after the
              transaction is successful.
            </p>

            <h6>Check Your Holdings:</h6>
            <p>
              {" "}
              You can now see your CRUDO holdings and transaction details in
              your dashboard.
            </p>

            <h5>New to Cryptocurrency?</h5>
            <h6>For Desktop Users</h6>

            <ul class="list_dot">
              <li>
                Install MetaMask: Add the MetaMask wallet extension to your
                Google Chrome browser from{" "}
                <a target="_blank" href="https://metamask.io/download/">
                  MetaMask Download.
                </a>
              </li>
              <li>
                Buy Cryptocurrency: Connect your wallet, then buy BNB, ETH,
                MATIC, AVAX, USDC, or USDT via card on the MetaMask marketplace
                at{" "}
                <a target="_blank" href="https://portfolio.metamask.io/">
                  MetaMask Portfolio.
                </a>{" "}
              </li>
              <li>
                {" "}
                Visit Crudo Protocol’s website:
                <a target="_blank" href="https://crudoprotocol.com/">
                  Go to Crudo Protocol
                </a>{" "}
              </li>
              <li>
                Connect Your Wallet: Click "Connect wallet" to link your wallet
                to the platform.
              </li>
              <li>
                Purchase CRUDO: Choose the amount of CRUDO tokens you want to
                buy or the amount of assets you want to spend, then click
                "Approve & Buy". If you need more assets, transfer them from a
                crypto exchange to your wallet or buy more through the MetaMask
                marketplace.
              </li>
            </ul>

            <h6>For Mobile Users</h6>

            <ul class="list_dot">
              <li>
                Download TrustWallet: Install the TrustWallet app from the Apple
                App Store or Google Play Store.
              </li>
              <li>
                Activate Your Wallet: Ensure you have the supported chains in
                your wallet. If not, add them following the steps at{" "}
                <a target="_blank" href="https://community.trustwallet.com/">
                  TrustWallet Community.
                </a>
              </li>
              <li>
                Fund Your Wallet: If you don't have assets, transfer them from a
                crypto exchange to your wallet address or buy more through the
                TrustWallet marketplace by clicking the "Buy" button and using
                your debit/credit card.
              </li>
              <li>
                Visit Crudo Protocol: Go to
                <a target="_blank" href="https://crudoprotocol.com/">
                  {" "}
                  Crudo Protocol.
                </a>{" "}
              </li>
              <li>
                Connect Your Wallet: Click "Connect Wallet" to link your wallet
                to the Crudo Protocol.
              </li>
              <li>
                Purchase CRUDO: Choose the amount of CRUDO tokens you want to
                buy or select how much of your assets you want to spend, then
                click "Approve and Buy".
              </li>
            </ul>
            <p>
              By following these steps, you can easily purchase CRUDO tokens
              whether you're using a desktop or a mobile device.
            </p>
            <h6>Disclaimer:</h6>
            <p>
              <small>
                When following the step-by-step process, always ensure you are
                on the official websites. Crudo Protocol only has one official
                website, which is hosted at{" "}
                <a target="_blank" href="https://crudoprotocol.com/">
                  {" "}
                  crudoprotocol.com.
                </a>{" "}
                We do not have any other platforms live at the moment.
              </small>
            </p>
            <p>
              <small>
                Be cautious of scammers and impersonators. The Crudo Protocol
                team will never ask you to transfer funds, share your seed
                phrase, or provide your private key.
              </small>
            </p>
            <p>
              <small>
                Please note that digital currencies may be unregulated in your
                jurisdiction. The value of digital currencies can fluctuate and
                may go down as well as up. Profits from digital currencies may
                be subject to capital gains or other taxes applicable in your
                jurisdiction.
              </small>
            </p>

            <h6></h6>
            <p></p>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};
