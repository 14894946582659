import React, { useEffect, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import { useLocation } from "react-router-dom";

function SwiperSlider() {
  const location = useLocation();
  const sliderRef = useRef(null);

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#crudo-app":
        scrollToRef(sliderRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  const slides = [
    "/img/slider1.png",
    "/img/slider2.png",
    "/img/slider3.png",
    "/img/slider4.png",
    "/img/slider5.png",
  ];

  const middleIndex = Math.floor(slides.length / 2);
  return (
    <>
      <section className="ex_box p70 pb-0" id="crudo-app" ref={sliderRef}>
        <div className="container  position-relative">
          <h2 className="hadding text-center mb-2">
            The <span className="color1">Crudo App</span> is Here!
          </h2>
          <div className="row">
            <div className="col-md-10 m-auto">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                loop={true} 
                slidesPerView={"auto"}
                initialSlide={middleIndex} 
                autoplay={{
                  delay: 3000, 
                  disableOnInteraction: true, 
                }}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2.5,
                }}
                modules={[EffectCoverflow, Autoplay]} 
                className="swiper_container"
              >
                {slides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div>
                      <img src={slide} alt={`slide_image_${index}`} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SwiperSlider;
