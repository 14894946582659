import React from "react";

export const LinkItem = ({ href, src, alt }) => {
  return (
    <>
      <div className="col-md-3 col-sm-4 col-6">
        <a target="_blank" rel="noopener noreferrer" href={href}>
          <img className="" src={src} alt={alt} />
        </a>
      </div>
    </>
  );
};
