import React, { useState, useEffect } from "react";
import { Navbar } from "../widgets/Navbar";

import { signupUser } from "../services/auth/auth";
import { toast } from "react-toastify";
import { Emailpattern } from "../pattern/Pattern";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";

import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { Footer } from "../widgets/Footer";
export const SignUp = () => {
  const navigate = useNavigate();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [name, setName] = useState("");
  const [mobile_number, setMobile_number] = useState("+44");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country_code, setCountry_code] = useState();
  const [mobile_numberErr, setMobile_numberErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [nameErr, setNameErr] = useState("");

  const checkOtpHandler = async () => {
    if (!name) {
      setNameErr("Name is required");
      return;
    }
    if (!email) {
      setEmailErr("This field is required");
      return;
    }
    if (!Emailpattern.test(email)) {
      setEmailErr("Please enter valid email");
      return;
    }
    if (!mobile_number) {
      setMobile_numberErr("This field is required");
      return;
    }

    const address = localStorage.getItem("address");
    if (!address) {
      return toast.error("Please connect with metamusk");
    }
    const reffer_from = localStorage.getItem("referral_code");
    let data = {
      name,
      email,
      mobile_number: number,
      country_code,
      address,
      reffer_from,
    };

    const result = await signupUser(data);
    if (result.status) {
      let token = result.token;
      localStorage.setItem("jwtToken", token);
      // login();
      setTimeout(() => {
        navigate("/#buy-now", { replace: true });
        setTimeout(() => {
          window.scrollTo(0, window.scrollY);
        }, 100); // Adjust delay if necessary
      }, 2000);

      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
  };

  // useEffect(() => {
  //   const jwtToken = localStorage.getItem("address");
  //   if (jwtToken) {
  //     navigate("/");
  //   }
  // }, [login]);

  const handleChange = (e) => {
    const err = "This field is required";
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
      if (value === "") {
        setNameErr("Name is required");
      } else {
        setNameErr("");
      }
    }
    if (name == "email") {
      setEmail(value);
      console.log(value == "", "hhh");
      if (value == "") {
        setEmailErr(err);
      } else {
        if (!Emailpattern.test(value)) {
          setEmailErr("Please enter valid email");
        } else {
          console.log("call");
          setEmailErr("");
        }
      }
    }
    if (name == "mobile_number") {
      setMobile_number(value);

      if (value === "") {
        setMobile_numberErr("Mobile Number is required");
      } else {
        setMobile_numberErr("");
      }
    }
  };

  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleOnChanges = (value, country) => {
    setMobile_number(value);

    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    if (!adjustedMobile) {
      setMobile_numberErr("Mobile Number is required");
    } else {
      setMobile_numberErr("");
    }
    setNumber(adjustedMobile);
    setCountry_code("+" + country.dialCode);
  };

  return (
    <>
      <Navbar />

      <section className="ex_box p70 crypto_page_bg pt170 minh100  bg1 pb-0 ">
        <div className="container  position-relative mt-5 pt-md-4">
          <div className="">
            <img src="img/s2.png" alt="s1 " class="s2 s0" />
            <img src="img/s3.png" alt="s1 " class="s3 s0" />
            <img src="img/s4.png" alt="s1 " class="s4 s0" />
            <div className="row align-items-center">
              <div className="col-md-6 pr-md-5 mb-4">
                <div className="login_box box2">
                  <h3 className="text-center mb-md-3">Sign Up</h3>
                  <div class="form-group">
                    <label className="mb-1"> User Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter  User Name"
                      class="input_item"
                      value={name}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{nameErr}</span>
                  </div>
                  <label className="mb-1">Email </label>
                  <div class="form-group position-relative">
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter  Email"
                      class="input_item"
                      value={email}
                      // disabled={inputDisable}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{emailErr}</span>
                  </div>

                  <>
                    <label className="mb-1">Mobile Number</label>
                    <div class="form-group  position-relative">
                      <PhoneInput
                        key={"phoneInput"}
                        country="IND"
                        value={mobile_number}
                        onChange={handleOnChanges}
                        className="input_item"
                        placeholder="Email/Mobile"
                        countryCodeEditable={false}
                        enableSearch={true}
                        inputProps={{
                          autoFocus: true,
                          name: "mobile_number",
                        }}
                        // disabled={disableGetCode}
                      />
                      <span className="text-danger">{mobile_numberErr}</span>
                    </div>
                  </>
                  {/* ) : (
                    ""
                  )} */}

                  <div class="form-group pt-3">
                    <button
                      className="btn w100"
                      onClick={checkOtpHandler}
                      // disabled={SignupDisable}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 m-auto ">
                <img src="img/app.png" alt="token " class="img-fluid " />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
